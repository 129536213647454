import React from 'react'

const CoursThree = () => {
    return (
        <>
            <div className=" h-[22em] w-full bg-[url('./bgimage/bgprives.jpeg')] text-white flex flex-col justify-between bg-center bg-cover bg-no-repeat">
                <div className=' w-full px-16 mt-[13em]'>
                    <h1 className='py-3 text-5xl md:text-7xl font-bold text-rcblue'></h1>
                </div>
            </div>
            <section className='flex flex-col justify-between mb-[6em] mx-16'>

                <h1 className='md:subtitlel relative pb-2 text-xl md:text-4xl text-center my-8'>LES COURS DE CONDUITE PRIVÉS</h1>
                <div className='grid md:grid-cols-2 max-w-[1240px] m-auto gap-8 md:mt-16 '>

                    <div className=" mt-16 md:mt-0 border border-gray-200 shadow-2xl">

                        
                        <div className="bg-rcblue p-5 pb-14">
                        <div className=' h-full md:h-[17em]'>
                        <img className=" h-full m-auto mt-[-2em] md:mt-[-4em]" src="images/ergotherapeutique.jpeg" alt="" />
                        </div>
                            
                        </div>
                        <div className='ml-8 mt-[-1em]'>
                        <span className=' bg-white  p-4 text-rcblue font-bold'>195$ +tx/ 2 heures</span>
                        </div>
                        <div className="py-5 px-5 md:px-12 bg-white">

                        <h5 className="my-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-rcblue">Cours d'Evaluation Ergothérapeutique :</h5>
                            <p className="mb-3 font-normal text-black">La SAAQ exige à certaines personnes en situation d'handicap une
                             évaluation Ergothérapeutique afin de détenir un permis de conduire.
                            </p>
                            <p className="mb-3 font-normal text-black">Avec ce cours, nous vous accompagnerons et vous apprendrons les 
                            techniques et manœuvres sécuritaires afin de mieux vous intégrer sur les réseaux routiers québécois et
                             canadiens.
                            </p>
                        </div>
                    </div>

                    <div className="mt-16 md:mt-0 border border-gray-200 shadow-2xl">

                        
                        <div className="bg-rcblue p-5 pb-14">
                        <div className=' h-full md:h-[17em]'>
                        <img className=" h-full m-auto mt-[-2em] md:mt-[-4em]" src="images/prives.jpg" alt="" />
                        </div>
                            
                        </div>
                        <div className='ml-8 mt-[-1em]'>
                        <span className=' bg-white  p-4 text-rcblue font-bold'>195$ +tx/ 2 heures</span>
                        </div>
                        <div className="py-5 px-5 md:px-12 bg-white">
                        <h5 className="my-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-rcblue">Cours de conduite accompagnée :</h5>
                            <p className="mb-3 font-normal text-black">À un certain âge, nous commençons à perdre nos réflexes et cela
                             peut altérer la conduite. Si vous avez 55 ans ou plus, ce cours vous aidera à garder des comportements
                              sécuritaires et vous transmettre les avantages des nouvelles technologies intégrées dans les voitures
                               modernes, ce qui augmentera votre niveau de sécurité sur la route.
                            </p>
                            <p className="mb-3 font-normal text-black">
                            Ce cours de conduite est un cours privé personnalisé. Nous vous invitons à nous contacter afin
                             d’évaluer votre besoin et nous mettrons en place un service spécifique et adapté afin d’assurer
                              votre préparation pour l’obtention de votre permis de conduire.
                            </p>
                        </div>
                    </div>

                </div>


            </section>
        </>
    )
}

export default CoursThree