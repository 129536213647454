import React from 'react'

const Makedifference = () => {
  return (
    <section className="w-full flex flex-col text-white justify-between bg-[url('./bgimage/route.jpeg')] bg-center bg-no-repeat  py-16 md:px-24 my-20">
        <div className='text-center mb-4'>
            <span className="subtitlel uppercase relative pb-2 text-xl">
              POURQUOI NOUS CHOISIR ?
            </span>
        </div>
        <h3 className="text-3xl text-center mt-8">Parce que c’est facile de conduire avec Rahma !</h3>
        <h4 className=' text-3xl text-center mt-8'>Notre équipe passionnée fera toujours en sorte de vous procurer le meilleur accompagnement</h4>
    </section>
  )
}

export default Makedifference