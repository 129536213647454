import React from 'react'


function Headerbgimg() {
  return (
    <section className='pt-20'>
    <div className="w-full bg-[url('./bgimage/header2.jpg')] bg-cover bg-center  ">
        <div className=' h-[30em] bg-black/60'>
        <div className='text-center text-white pb-2 pt-[8em] py-8'>
                
                <h1 className='py-3 text-5xl md:text-7xl font-bold uppercase'>École de conduite à Saint-Léonard</h1>
            </div>
        </div>
           
            
       
    </div>
      <div className='grid grid-cols-1 md:px-36  lg:grid-cols-3 text-center place-items-center xl:mt-[-2em]'>

        <div className=' bg-rcblue p-8 shadow-2xl intro h-[20em]'>
        <img className='m-auto h-[8em]' src="images/icon1.png" alt="" />
          <h2 className='text-xl mt-2 font-bold text-white whitespace-nowrap'>Bénéficiez de nos différents cours</h2>
          <p className='text-white mt-2'>Apprenez le code de la route et
            devenez incollable en conduite
          </p>
        </div>

        <div className=' bg-rcblue p-8 shadow-2xl mt-2 xl:mt-0 intro h-[20em]'>
        <img className='m-auto h-[8em]' src="images/icon2.png" alt="" />
          <h2 className='text-xl mt-2 font-bold text-white'>Un accompagnement complet</h2>
          <p className='text-white mt-2'>Nous serons avec vous jusqu’à 
            l’obtention de votre permis !
          </p>
        </div>

        <div className=' bg-rcblue p-8 shadow-2xl mt-2 xl:mt-0 intro h-[20em]'>
        <img className='m-auto h-[8em]' src="images/icon3.png" alt="" />
          <h2 className='text-xl mt-2 font-bold text-white'>Une équipe passionnée</h2>
          <p className='text-white mt-2'>Qui vous donne toutes les bases
            pour une conduite responsable
          </p>
        </div>

      </div>
    
  </section>
  )
}

export default Headerbgimg