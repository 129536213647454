import React from 'react'
import About from '../elements/About';
import Courseshome from '../elements/Courseshome';
import Driveworks from '../elements/Driveworks';
import Headerbgimg from '../elements/Headerbgimg';
import Makedifference from '../elements/Makedifference';

const Home = () => {
  return (
    <>
    <Headerbgimg/>
    <About/>
    <Courseshome/>
    {/* <Driveworks/> */}
    <Makedifference/>
    </>
  )
}

export default Home