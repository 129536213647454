import React from 'react'
import { Link } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
const Courseshome = () => {
  return (
    <section id='cours' className='bg-zinc-200 flex mt-24 md:mt-0 flex-col justify-between'>
      <div className='text-center mt-8'>
        <span className="subtitlel uppercase relative pb-2 text-xl">
          Nos cours
        </span>
        <h3 className='mt-8 text-4xl'>Plusieurs cours à votre disposition</h3>
      </div>

      <div className=' grid drid-cols-1 lg:grid-cols-3 relative gap-y-16 gap-x-8 px-4 md:px-36 py-12 sm:pt-20'>

        <Link to='/conduitereguliers'>
          <div className='bg-white shadow-2xl'>
            <img src="images/cours1.jpeg" alt="/" className=' w-[100%]' />
            <div className='p-8'>
              <span className='text-center text-md p-2 bg-rcblue text-white mt-[-4rem] absolute'>À partir de 825$ +tx(+ livres 1000.28$)</span>
              <h3 className='font-bold text-xl'>COURS DE CONDUITE RÉGULIERS</h3>

            </div>
          </div>
        </Link>
        
        <Link to='/conduiteperfectionnement'>
          <div className='bg-white shadow-2xl'>
            <img src="images/cours3.jpeg" alt="/" className=' w-[100%]' />
            <div className='p-8'>
              <span className='text-center text-md p-2 bg-rcblue text-white mt-[-4rem] absolute'>À partir de 45$ tous inclus</span>
              <h3 className='font-bold text-xl'>COURS DE CONDUITE DE PERFECTIONNEMENT</h3>

            </div>
          </div>
        </Link>

        <Link to='/conduiteprives'>
          <div className='bg-white shadow-2xl'>
            <img src="images/cours2.jpeg" alt="/" className=' w-[100%]' />
            <div className='p-8'>
              <span className=' text-center text-md p-2 bg-rcblue text-white mt-[-4rem] absolute'>À partir de 45$/h</span>
              <h3 className='font-bold text-xl'>COURS DE CONDUITE PRIVÉS</h3>
            </div>
          </div>
        </Link>

      </div>

      <div className='text-center mt-8'>
        <span className="subtitlel uppercase relative pb-2 text-xl">
          Nous proposons aussi
        </span>
      </div>

      <div className=" my-8 flex flex-col bg-rcblue border shadow-2xl md:flex-row m-auto md:max-w-xl">
        
        <div className='object-cover w-full md:w-[80em] '>
          <img className=" h-full w-full" src="images/location.jpeg" alt="" />
        </div>
        
        <div className="flex flex-col justify-between p-4 leading-normal">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Location pour examen SAAQ 55$/h tous inclus</h5>
          <p className="mb-3 font-normal text-white">Tout le monde n'a pas facilement accès à une voiture pour son examen de conduite et toutes les voitures ne sont pas admissibles à l'examen. C'est pourquoi nous pouvons vous proposer une location pour votre examen. Nos voitures sont certifiées par la SAAQ.</p>
        </div>
      </div>


    </section>
  )
}

export default Courseshome