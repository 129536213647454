import React from 'react'

const Cours = () => {
    return (
        <>
            <div className=" w-full h-[20em] bg-[url('./bgimage/bgreguliers.jpeg')] text-white flex flex-col justify-between bg-center bg-cover bg-no-repeat">
                <div className=' w-full px-16 mt-[13em]'>
                    <h1 className='py-3 text-5xl md:text-7xl font-bold text-rcblue'></h1>
                </div>
            </div>
            <section className='flex flex-col justify-between mb-[6em] mx-16'>

                <h1 className='md:subtitlel relative pb-2 text-4xl text-center my-8'>LES COURS DE CONDUITE RÉGULIERS</h1>
                <div className='grid md:grid-cols-2 max-w-[1240px] m-auto gap-8 md:mt-16 '>

                    <div className="mt-16 md:mt-0  border border-gray-200 shadow-2xl">


                        <div className="bg-rcblue p-5 pb-14">
                            <div className=' h-full md:h-[17em]'>
                                <img className=" h-full m-auto mt-[-2em] md:mt-[-4em]" src="images/debutants.jpg" alt="" />
                            </div>
                           
                        </div>
                        <div className='ml-8 mt-[-1em]'>
                            <span className=' bg-white p-4 text-rcblue font-bold'>825$ +tx</span>
                        </div>

                        <div className="py-5 px-5 md:px-12 bg-white">

                        <h5 className="my-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-rcblue">Cours de conduite obligatoire pour débutant(e)s :</h5>
                            <p className="mb-3 font-normal text-black">Ce cours de conduite concerne toutes les personnes ayant plus de 16 ans et
                                n’ayant jamais eu de permis d’apprenti conducteur du Québec.
                            </p>
                            <p className="mb-3 font-normal text-black">Il s’étale sur 12 mois et est constitué de 12 cours théoriques de 2 heures chacun et
                                15 cours pratiques de 55 minutes chacun.
                            </p>
                            <ul>
                                <li>Cours théoriques : 24 heures</li>
                                <li>Sorties sur la route : 15 heures</li>
                                <li className=' text-rcblue'>Deux livres 45$ +tx</li>
                                <li className=' text-rcblue font-bold'>Obtenez une carte cadeau de 50$ après la 1ère phase (6 cours)</li>
                            </ul>
                        </div>
                    </div>

                    <div className="mt-16 md:mt-0 border border-gray-200 shadow-2xl">


                        <div className="bg-rcblue p-5 pb-14">
                        <div className=' h-full md:h-[17em]'>
                            <img className=" h-full m-auto mt-[-2em] md:mt-[-4em]" src="images/accompagnee.jpg" alt="" />
                        </div>
                           
                        </div>
                        <div className='ml-8 mt-[-1em]'>
                            <span className=' bg-white  p-4 text-rcblue font-bold'>800$ +tx</span>
                        </div>
                        <div className="py-5 px-5 md:px-12  bg-white">

                        <h5 className="my-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-rcblue">Cours de conduite accompagnée :</h5>
                            <p className="mb-3 font-normal text-black">Si vous disposez déjà d’un permis
                                d’apprenti conducteur et que vous voulez bien apprendre à conduire et
                                gagner plus d’expérience en toute sécurité, ce cours est fait pour vous.
                            </p>
                        </div>
                    </div>

                </div>


            </section>
        </>
    )
}

export default Cours