import React from 'react';
import swal from 'sweetalert';
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Contact = () => {

    const { register, handleSubmit } = useForm();
    let navigate = useNavigate();

    const onSubmit = async data => {


        const res = await axios.post('/api/contact', data);

        swal({
            text: "Merci de nous avoir contacté. Nous vous répondrons le plus vite possible.",
            icon: "success",
            button: "Terminer",
        });
        return navigate("/")

        /* axios.post('http://127.0.0.1:8000/contact', data)
            .then(res => {
                if (res.data.status === 200) {
                    swal("", res.data.message, "success");
                }
            })
            .catch(err => console.log(err)) */

    };
    return (
        <>
            <div className=" w-full h-[20em] bg-[url('./bgimage/bgcontact.jpeg')] text-white flex flex-col justify-between bg-center bg-cover bg-no-repeat">
                <div className=' w-full px-16 mt-[13em]'>
                    <h1 className='py-3 text-5xl md:text-7xl font-bold text-rcblue'></h1>
                </div>
            </div>
            <section className='flex flex-col justify-between mb-[6em] mx-4 md:mx-16'>


                <div className='grid grid-col-1 md:grid-cols-2 mt-24 gap-16 '>
                    {/* <div className=''>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d87338.91588747295!2d-71.48799419403076!3d46.8369743454328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1zYXV0by3DqWNvbGUgw6AgcHJveGltaXTDqSBkZSBRdcOpYmVjLCBRQywgQ2FuYWRh!5e0!3m2!1sfr!2sdz!4v1657027203562!5m2!1sfr!2sdz" className=' md:w-[600px]' height="450" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div> */}
                    <div className=''>
                        <h1 className="subtitlel uppercase relative pb-2 text-xl mb-8">
                            Contactez nous
                        </h1>
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <div className="relative z-0 md:w-full mb-6 group">
                                <input {...register("name")} type="text" name="name" className="block py-2.5 px-0 md:w-full text-lg text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                                <label for="name" className="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nom et Prénom</label>
                            </div>
                            <div className="relative z-0 w-full mb-6 group">
                                <input {...register("email")} type="email" name="email" className="block py-2.5 px-0 md:w-full text-lg text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                                <label for="mail" className="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Adresse mail</label>
                            </div>

                            <div className="relative z-0 w-full mb-6 group">
                                <input {...register("object")} type="text" name="object" className="block py-2.5 px-0 md:w-full text-lg text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" required />
                                <label for="object" className="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-black duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Object</label>
                            </div>

                            <div className="relative z-0 w-full mb-6 group">
                                <label for="message" class="block mb-2 text-lg font-medium text-black dark:text-black">Message</label>
                                <textarea {...register("message")} name="message" rows="4" class="block p-2.5 md:w-full text-lg text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Ecrivez ici ..."></textarea>
                            </div>

                            <button type="submit" className="text-white bg-black hover:bg-white hover:text-black hover:border hover:border-black    font-medium rounded-lg text-lg md:w-full  px-5 py-2.5 text-center ">Envoyer</button>
                        </form>
                    </div>
                </div>


            </section>
        </>
    )
}

export default Contact