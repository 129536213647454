import React from 'react'

const CoursTwo = () => {
    return (
        <>
            <div className=" w-full h-[20em] bg-[url('./bgimage/bgperfectionnement.jpeg')] text-white flex flex-col justify-between bg-center bg-cover bg-no-repeat">
                <div className=' w-full px-16 mt-[13em]'>
                    <h1 className='py-3 text-5xl md:text-7xl font-bold text-rcblue'></h1>
                </div>
            </div>
            <section className='flex flex-col justify-between mb-[6em] mx-16'>

                <h1 className='md:subtitlel relative pb-2 text-xl md:text-4xl text-center my-8'>LES COURS DE CONDUITE DE PERFECTIONNEMENT</h1>
                <div className='grid md:grid-cols-3 max-w-[1240px] m-auto gap-8 md:mt-16 '>

                    <div className="  border border-gray-200 shadow-2xl">


                        <div className="bg-rcblue p-5 pb-14">
                        <div className=' h-full xl:h-[17em]'>
                            <img className=" h-full m-auto mt-[-2em] md:mt-[-4em]" src="images/perfectionnement.jpg" alt="" />
                        </div>
                            
                        </div>

                        <div className='ml-8 mt-[-1em]'>
                        <span className=' bg-white p-4 text-rcblue font-bold'>30$ +tx/h</span>
                        </div>
                        <div className="py-5 px-5 xl:px-12 bg-white">
                            
                        <h5 className="my-4 xl:text-2xl font-bold tracking-tight text-gray-900 dark:text-rcblue">Cours de conduite de
                                perfectionnement :</h5>

                            <p className="mb-3 font-normal text-black">Ce cours concerne tous les résidents permanents arrivés au Québec durant
                                les 6 derniers mois et détenant un permis de conduire étranger éligible au processus d’échange de permis Québec.
                            </p>
                            <p className="mb-3 font-normal text-black">Il vous permettra de connaître les règles de conduites Québécoises afin de
                                réussir votre examen d’échange de permis de conduire.
                            </p>
                            <ul className=' text-rcblue'>
                                <li>35$ +tx/h pour moins de 10 cours</li>
                                <li>30$ +tx/h pour plus de 10 cours</li>
                            </ul>
                        </div>
                    </div>

                    {/* <div className=" bg-rcblue border border-gray-200 shadow-2xl">
   
        <img className=" m-auto mt-[-2em]" src="images/cours.png" alt="" />
        <div className="p-5">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Perfectionnement pour Nouveaux Arrivants :</h5>
        </div>
        <div className="p-5 bg-white">
                
            
            <p className="mb-3 font-normal text-black">Ce cours de conduite concerne toutes les personnes ayant plus de 16 ans et
                n’ayant jamais eu de permis d’apprenti conducteur du Québec.
            </p>
            <p className="mb-3 font-normal text-black">Il s’étale sur un an et est constitué de 12 cours théoriques de 2 heures chacun et
                    15 Cours pratique de 55 minutes chacun.
            </p>
            <ul>
                <li>prix: 962$ +tx</li>
                <li>Cours Théorique : 24 Heures</li>
                <li>Sortie sur la Route : 15 heures</li>
            </ul>
        </div>
    </div> */}

                    <div className="mt-16 md:mt-0 border border-gray-200 shadow-2xl">
                        <div className="bg-rcblue p-5 pb-14">
                        <div className=' h-full xl:h-[17em]'>
                            <img className=" h-full m-auto mt-[-2em] md:mt-[-4em]" src="images/examenpratique.jpg" alt="" />
                        </div>
                            
                        </div>
                        <div className='ml-8 mt-[-1em]'>
                        <span className=' bg-white p-4 text-rcblue font-bold'>35$ +tx/h</span>
                        </div>
                        <div className="py-5 px-5 xl:px-12 bg-white">

                        <h5 className="my-4 xl:text-2xl font-bold tracking-tight text-gray-900 dark:text-rcblue">Cours de Simulation d'examen Pratique :</h5>
                            <p className="mb-3 font-normal text-black">Si vous prévoyez de passer votre permis de conduire auprès de la SAAQ, ce cours vous permettra de simuler votre examen de conduite parfaitement et vous préparer aux erreurs éventuelles que vous pourrez faire afin de mieux vous y préparer.
                            </p>
                        </div>
                    </div>

                    <div className="mt-16 md:mt-0 border border-gray-200 shadow-2xl">
                        <div className="bg-rcblue p-5 pb-14">
                        <div className=' h-full xl:h-[17em]'>
                            <img className=" h-full m-auto mt-[-2em] md:mt-[-4em]" src="images/manuelle.jpg" alt="" />
                        </div>
                            
                        </div>
                        <div className='ml-8 mt-[-1em]'>
                        <span className=' bg-white  p-4 text-rcblue font-bold'>45$ +tx/h</span>
                        </div>
                        <div className="py-5 px-5 xl:px-12 bg-white">
                        <h5 className="my-4 xl:text-2xl font-bold tracking-tight text-gray-900 dark:text-rcblue">Cours de Conduite sur Voiture Manuelle :</h5>
                            <p className="mb-3 font-normal text-black">Comme son nom l’indique, ce cours est fait pour vous préparer
                                à passer un examen de permis de conduire avec une voiture manuelle. En effet, il est dit que la conduite
                                de voiture manuelle est plus facile mais elle nécessite quand même de l’entrainement, surtout lorsque
                                nous n’avons pas l’habitude de conduire ce type de voitures.
                            </p>
                            <p className="mb-3 font-normal text-black">
                                Si vous comptez passer votre permis de conduire avec une voiture manuelle, ce cours est donc fait pour vous.
                            </p>
                        </div>
                    </div>

                </div>


            </section>
        </>
    )
}

export default CoursTwo