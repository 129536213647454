import React from 'react'

const About = () => {
  return (
    <div>
        <div className='w-full xl:h-screen flex flex-col justify-between'>
        <div className='grid md:grid-cols-2 max-w-[1240px] m-auto '>
            <div className='flex flex-col  md:items-start w-full pt-12 md:pt-0 px-16'>
                <span className="subtitlel uppercase relative pb-2 text-xl">
                  Qui sommes-nous ?
                </span>
                <h3 className=' mt-8 text-2xl'>Une école de conduite accessible et basée sur
                  l’accompagnement et l’écoute de nos élèves</h3>
                <p className='mt-8'>Notre équipe qualifiée et passionnée vous propose
                  différents cours répondants à vos attentes et fera en
                  sorte de vous accompagner tout au long du processus
                  d’apprentissage, de l’étude du code de la route
                  jusqu’à l’obtention de votre permis de conduire !
                </p>

                <h4 className='mt-8  text-xl mb-4 md:mb-0 md:text-2xl'>Nous sommes certifiés :</h4>
                <div className='grid grid-cols-1 md:grid-cols-2 ml-[-1em] gap-12 my-4 md:mt-8'>

                <a href="https://aqtr.com/ecoles-conduite/informations-eleves/ecole-conduite-lewis-longueuil" target="_blank">
                  <div className='h-[4em] md:h-[5em] p-4'>
                    <img  src="images/SAAQ.png" alt="" />
                  </div>
                </a>
                <a href="https://saaq.gouv.qc.ca/" target="_blank">
                  <div className='h-[4em] md:h-[5em] p-4'>
                  <img  src="images/aqtr.png" alt="" />
                  </div>
                </a> 
                    
                  
                </div>
            </div>
            <div className=' relative'>
              <div className=' relative'>
              <img src="images/about.jpeg" alt="/" />
              </div>
              <a href="#cours">
              <div className=' absolute right-0 bottom-[-3em] xl:bottom-[-4em]'>
              <img className='border-8 border-white h-[10em] xl:h-[100%]' src="images/image-2.jpg" alt="/" />
              </div>
              </a>
              
            </div>
        </div>
    </div>
    </div>
  )
}

export default About