/* import './App.css'; */
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import Footer from './components/layout/footer';
import Navbar from './components/layout/navbar';
import Contact from './components/pages/Contact';
import Cours from './components/pages/Coursone';
import CoursTwo from './components/pages/Courstwo';
import CoursThree from './components/pages/Courthee';
import Home from './components/pages/Home';
import ScrollToTop from './components/ScrollToTop';

import axios from "axios";
axios.defaults.baseURL = "http://ecrahma.ca/apiecrahma/";
axios.defaults.headers.post['Content-Type'] = '*';

axios.defaults.headers.post['Accept'] = 'application/json';

function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop/>
      <Navbar/>
        <Routes>
          <Route exact path={'/'} element={<Home/>}/>
          <Route exact path={'/conduitereguliers'} element={<Cours/>}/>
          <Route exact path={'/conduiteperfectionnement'} element={<CoursTwo/>}/>
          <Route exact path={'/conduiteprives'} element={<CoursThree/>}/>
          <Route exact path={'/contact'} element={<Contact/>}/>
        </Routes>
      <Footer/>
    </BrowserRouter>
    </>
  );
}

export default App;
