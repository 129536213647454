import React, { useState } from 'react';
import { MenuIcon, XIcon, PhoneIcon } from '@heroicons/react/outline'
import { BsFacebook } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Navbar = () => {

  const [nav, setNav] = useState(false)
  const handelClick = () => setNav(!nav)
  

  const [cousDropdown, setCousDropdown] = useState(false)
    const handelClickcousDropdown = () => setCousDropdown(!cousDropdown)
    const handelClickcousDropdownout = () => setCousDropdown(false)

  return (
    <section className='w-screen h-[80px] z-10 text-white bg-black fixed drop-shadlow-lg cursor-pointer'>
      <div className='px-2 flex justify-between items-center w-full h-full '>
        <div className='xl:ml-16'>
          <ul className='hidden md:flex'>
          <Link onClick={handelClickcousDropdownout} to="/"><li>Accueil</li></Link>

            <li>
              <button onClick={handelClickcousDropdown} id="triggerEl" aria-expanded="false" className=" flex items-center">
                Cours
                <svg sidebar-toggle-item className="w-6 h-6 pt-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </button>

              <div id="targetEl" className={!cousDropdown ? 'hidden' : ' absolute mt-4 '}>
                <ul className="w-58 text-sm font-medium  bg-white  text-black">
                <Link onClick={handelClickcousDropdown} to="conduitereguliers" ><li className="py-2 hover:bg-rcblue px-4 w-full border-b border-rcblue dark:border-black">COURS DE CONDUITE RÉGULIERS </li></Link>
                  <Link onClick={handelClickcousDropdown} to="/conduiteperfectionnement" > <li className="py-2 hover:bg-rcblue px-4 w-full border-b dark:border-black">COURS DE CONDUITE DE PERFECTIONNEMENT </li></Link>
                  <Link onClick={handelClickcousDropdown} to="/conduiteprives" ><li className="py-2 hover:bg-rcblue px-4 w-full border-b dark:border-black">COURS DE CONDUITE PRIVÉS </li></Link>
                </ul>
              </div>
            </li>
            
            <li><Link onClick={handelClickcousDropdownout} to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className=' absolute md:relative ml-4 xl:ml-[-8em]'>
          <Link to="/"><img className='h-12' src="images/logow.png" alt="" /></Link>
        </div>
        <div className='hidden md:flex pr-4 mr-16'>
        <a className="nav-link" href="tel:438765602"><span className='px-3 py-2 flex spannumber'><PhoneIcon className='w-5 mr-2' /> (438) 476-5602</span> </a>
        </div>
        <div className='md:hidden text-white' onClick={handelClick}>
          <MenuIcon className='w-5' />
        </div>
      </div>
      <div className={!nav ? 'hidden' : 'absolute bg-black w-full px-8'}>
        <ul>
         
          <Link to="/"> <li onClick={handelClick} className='border-b-2 border-white w-full'>Accueil</li></Link>
          <li onClick={handelClickcousDropdown} className='border-b-2 border-white w-full'>
            <button  id="triggerEl" aria-expanded="false" className=" flex items-center">
                Cours
                <svg sidebar-toggle-item className="w-6 h-6 pt-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </button>

              <div id="targetEl" className={!cousDropdown ? 'hidden' : ' mt-4 '}>
                <ul className="w-58 text-sm font-medium border  bg-white  text-black">
                <Link onClick={handelClick} to="conduitereguliers" ><li className="py-2 hover:bg-rcblue px-4 w-full rounded-t-lg border-b border-rcblue dark:border-black">COURS DE CONDUITE RÉGULIERS </li></Link>
                  <Link onClick={handelClick} to="/conduiteperfectionnement" > <li className="py-2 hover:bg-rcblue px-4 w-full border-b dark:border-black">COURS DE CONDUITE DE PERFECTIONNEMENT </li></Link>
                  <Link onClick={handelClick} to="/conduiteprives" ><li className="py-2 hover:bg-rcblue px-4 w-full border-b dark:border-black">COURS DE CONDUITE PRIVÉS </li></Link>
                </ul>
              </div>
            </li>

          <Link onClick={handelClick} to="/contact"><li className='border-b-2 border-white w-full'>Contact</li></Link>
          <a href="tel:438765602"><span className='px-8 my-6 py-3 flex'><PhoneIcon className='w-5 mr-2' /> (438) 476-5602</span></a>
        </ul>
      </div>
    </section>
  )
}

export default Navbar