import React from 'react'
import { FaMapMarkedAlt } from 'react-icons/fa';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import {IconContext} from 'react-icons';
import { AiFillFacebook, AiFillPhone, AiFillMail, AiFillInstagram } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="w-full  text-white  bg-[url('./bgimage/footer.jpg')] bg-center bg-no-repeat bg-cover py-16 md:px-24 mt-8">
        <div className='grid grid-cols-1 place-items-stretch md:grid-cols-3 '>

            <div className='mx-4 md:mx-0'>
            <Link to="/"> <img src="images/logow.png" alt="" width="200px" className='' /></Link>
                <p className=' mt-6'>L’auto-école où vous serez accompagné par des professionnels 
                    lors de nombreux cours adaptés à votre situation et jusqu’à la réussite 
                    de vos examens de conduite.
                </p>
                <p className=' mt-8 font-bold'>Prenez la route en toute sécurité!</p>

                {/* <div className='flex mr-8 p-8 box-contact-icon'>
                    <a href=''> <AiFillFacebook className='text-3xl  text-rcblue' /> </a> 
                    <a href=''> <AiFillInstagram className='text-3xl  text-rcblue' /> </a> 
                </div> */}
            </div>

            <div className='mt-10 md:ml-16 md:mt-0'>
                <h5 className='subtitlel uppercase relative pb-2 text-3xl ml-4'>Nos cours</h5>
                <ul>
                    <li>COURS DE CONDUITE RÉGULIERS</li>
                    <li>COURS DE CONDUITE DE PERFECTIONNEMENT</li>
                    <li>COURS DE CONDUITE PRIVÉS</li>
                </ul>
                
            </div>

            <div className=' mt-10 md:mt-0 md:m-auto'>
                <h5 className='subtitlel uppercase relative pb-2 text-3xl ml-4'>Contactez-nous</h5>
                <IconContext.Provider value={{size:'35',color:'#ffffff'}}>
                
                <ul>
                    <li className='flex items-center'>

                        <div className=' text-center mr-4'>
                        
                            <FaMapMarkedAlt/>
                        
                        </div>

                        <div>
                        8436, Langelier, St-Léonard<br/> (Québec) H1P 2C2
                        </div>
                    </li>
                    <li className='flex items-center mb-4'>

                        <div className=' text-center mr-4'>
                        
                            <AiOutlinePhone />
                        
                        </div>

                        <div>
                        <a className="nav-link" href="tel:438765602">(438) 476-5602</a><br/>
                        <a className="nav-link" href="tel:4388368644">(438) 836-8644</a>
                        </div>
                    </li>
                    <li className='flex items-center'>

                        <div className=' text-center mr-4'>
                        
                            <FaMapMarkedAlt/>
                        
                        </div>

                        <div>
                        3180 Ch. de Chambly, Longueuil. <br/>bureau 103 (Québec) QC J4L 1N6 
                        </div>
                    </li>

                    <li className='flex items-center mb-4'>

                        <div className=' text-center mr-4'>
                        
                            <AiOutlinePhone />
                        
                        </div>

                        <div>
                        <a className="nav-link" href="tel:438765602">(438) 356-2096</a>
                        </div>
                    </li>

                    <li className='flex items-center mb-4'>

                        <div className=' text-center mr-4'>
                        
                            <AiOutlineMail />
                        
                        </div>

                        <div>
                        <a className="nav-link" href="email:contact@ecrahma.ca">contact@ecrahma.ca</a>
                        </div>
                    </li>

                </ul>
                </IconContext.Provider>
            </div>
        </div>

        <div className=' mt-4 border-t-2'>

        </div>

    </footer>
  )
}

export default Footer